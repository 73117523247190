import React from "react"
// import { Row, Col, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ChainsimFloater from "../components/chainsim-floater"
import DrillCategory from "../components/drill-category"
import lesson1JSON from "../../scripts/lesson1.json"

const TestDrillListPage = props => (
  <Layout location={props.location}>
    <SEO title="Simulator" keywords={[`gatsby`, `react`, `bootstrap`]} />
    <ChainsimFloater />
    <DrillCategory drills={lesson1JSON} />
  </Layout>
)

export default TestDrillListPage
